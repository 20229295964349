import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import TitleCTA from '../components/subcomponents/TitleCTA'
import BannerHeader from '../components/subcomponents/BannerHeader'
import clientLogos from '../constants/clientLogos'

const IndexPage = () => (
  <Layout>
    <BannerHeader
      clientLogos={clientLogos}
      textBannerLogos="Hemos desarrollado soluciones para ..."
    >
      <TitleCTA
        title="TU PARTNER <br /> ESTRATÉGICO"
        text={`
        En BAULEN <strong></strong> te ayudamos a crear productos digitales. <br />
        Especialistas en desarrollo de aplicaciones móviles, aplicaciones web y chatbots.
      `}
        ctaText="CONTACTA"
      />
    </BannerHeader>
  </Layout>
)
// < Link to = "/page-2/" > Go to page 2</Link >

export default IndexPage
