const clientLogos = [
  {
    src: require('../images/logo-randstad-baulen.svg'),
    alt: 'randstad',
    href: 'https://www.randstad.es/',
  },
  {
    src: require('../images/logo-udacity-baulen.svg'),
    alt: 'Udacity',
    href: 'https://www.udacity.com/',
  },
  //   {
  //     src: require('../images/logo-cafe-leather-supply-blanco-simple.svg'),
  //     alt: 'Cafe Leather Supply',
  //     href: 'https://cafeleathersupply.com/',
  //   },
  {
    src: require('../images/telefonica-logo-baulen.svg'),
    alt: 'Telefónica',
    href: 'https://www.telefonica.com/',
  },
  {
    src: require('../images/logo-iese-baulen.png'),
    alt: 'IESE',
    href: 'https://www.iese.edu/',
  },
]

export default clientLogos
