import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { marginLeftRitght, widthMobile } from '../../constants/sizes'
const ClientLogo = styled.img`
  height: 24px;
  margin-left: 30px;
  @media (max-width: ${widthMobile}) {
    margin-left: 0;
  }
`
const SubBannerClientLogosWrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 ${marginLeftRitght};
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const SubBannerClientLogosInner = styled.div`
  border-top: 2px solid #fff;
  display: flex;
  width: 100%;
  height: 95px;
  align-items: center;
  padding-top: 16px;
`
const SubBannerClientLogosJustLogos = styled.div`
  width: 550px;
  max-width: 100%;
  padding-left: 30px;
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${widthMobile}) {
    padding-left: 0;
    justify-content: space-between;
  }
`
const LittleText = styled.span`
  height: 2.5rem;
  color: #fff;
  font-family: Maison Neue;
  font-weight: 500;
  font-size: 0.63rem;
  letter-spacing: 0.09375rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  @media (max-width: ${widthMobile}) {
    display: none;
  }
`

export default class SubBannerClientLogos extends Component {
  static propTypes = {
    clientLogos: PropTypes.array,
    text: PropTypes.string,
  }
  render() {
    const { clientLogos, text } = this.props
    if (clientLogos && Array.isArray(clientLogos) && clientLogos.length > 0) {
      return (
        <SubBannerClientLogosWrapper>
          <SubBannerClientLogosInner>
            {text && <LittleText>{text}</LittleText>}
            <SubBannerClientLogosJustLogos>
              {clientLogos.map((logo, index) => (
                <a
                  key={index}
                  href={logo.href}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  className="client-logo-link"
                >
                  <ClientLogo
                    className="client-logo"
                    src={logo.src}
                    alt={logo.alt}
                  />
                </a>
              ))}
            </SubBannerClientLogosJustLogos>
          </SubBannerClientLogosInner>
        </SubBannerClientLogosWrapper>
      )
    }
    return null
  }
}
