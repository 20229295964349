import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SubBannerClientLogos from './SubBannerClientLogos'

const bgimage = require('../../images/foto-bg-baulen.jpg')
const BannerHeaderDiv = styled.div`
  padding-top: 32px;
  min-height: 100vh;
  display: flex;
  position: relative;
  background-color: #0f1316;
  background-image: url(${bgimage});
  background-repeat: no-repeat;
  background-size: cover;
`
const SelfCentered = styled.div`
  align-self: center;
  max-width: 100%;
`

export default class BannerHeader extends Component {
  static propTypes = {
    clientLogos: PropTypes.array,
  }

  render() {
    const { children, clientLogos, textBannerLogos } = this.props
    return (
      <BannerHeaderDiv className="banner-header">
        <SelfCentered className="self-centerd">{children}</SelfCentered>
        {clientLogos && (
          <SubBannerClientLogos
            clientLogos={clientLogos}
            text={textBannerLogos}
          />
        )}
      </BannerHeaderDiv>
    )
  }
}
