import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../constants/colors'
import { widthMobile } from '../../constants/sizes'
// const CTATextWrapper = styled.div`
//   bottom: 0px;
//   box-sizing: border-box;
//   color: rgb(17, 21, 23);
//   display: inline-block;
//   height: 141px;
//   left: 0px;
//   position: relative;
//   right: 0px;
//   text-align: left;
//   text-decoration: none solid rgb(17, 21, 23);
//   text-rendering: optimizeLegibility;
//   text-size-adjust: 10%;
//   top: 0px;
//   width: 240px;
//   column-rule-color: rgb(17, 21, 23);
//   perspective-origin: 120px 70.5px;
//   transform-origin: 120px 70.5px;
//   caret-color: rgb(17, 21, 23);
//   border: 0px none rgb(17, 21, 23);
//   border-radius: 2px 2px 2px 2px;
//   font: normal normal 300 normal 96px / 96px 'Maison Neue', sans-serif;
//   margin: 0px 0px -50px;
//   outline: rgb(17, 21, 23) none 0px;
//   overflow: hidden;
//   padding: 0px 0px 50px;
// `
const DIV1 = styled.div`
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(17, 21, 23);
  display: inline-block;
  height: 141px;
  left: 0px;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgb(17, 21, 23);
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  top: 0px;
  width: 240px;
  column-rule-color: rgb(17, 21, 23);
  perspective-origin: 120px 70.5px;
  transform-origin: 120px 70.5px;
  caret-color: rgb(17, 21, 23);
  border: 0px none rgb(17, 21, 23);
  border-radius: 2px 2px 2px 2px;
  font: normal normal 300 normal 96px / 96px 'Maison Neue', sans-serif;
  margin: 0px 0px -50px;
  outline: rgb(17, 21, 23) none 0px;
  overflow: hidden;
  padding: 0px 0px 50px;
  width: 100%;
  &:after {
    box-sizing: border-box;
    color: rgb(17, 21, 23);
    text-align: left;
    text-decoration: none solid rgb(17, 21, 23);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    column-rule-color: rgb(17, 21, 23);
    caret-color: rgb(17, 21, 23);
    border: 0px none rgb(17, 21, 23);
    font: normal normal 300 normal 96px / 96px 'Maison Neue', sans-serif;
    outline: rgb(17, 21, 23) none 0px;
  }
  &:before {
    box-sizing: border-box;
    color: rgb(17, 21, 23);
    text-align: left;
    text-decoration: none solid rgb(17, 21, 23);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    column-rule-color: rgb(17, 21, 23);
    caret-color: rgb(17, 21, 23);
    border: 0px none rgb(17, 21, 23);
    font: normal normal 300 normal 96px / 96px 'Maison Neue', sans-serif;
    outline: rgb(17, 21, 23) none 0px;
  }
`
const A2 = styled.a`
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  height: 64px;
  left: 0px;
  letter-spacing: 1px;
  position: relative;
  right: 0px;
  text-align: left;
  text-decoration: underline solid rgb(255, 255, 255);
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  text-transform: uppercase;
  top: 0px;
  width: 240px;
  column-rule-color: rgb(255, 255, 255);
  perspective-origin: 120px 32px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 120px 32px;
  caret-color: rgb(255, 255, 255);
  /* border: 2px solid rgb(255, 255, 255); */
  background-color: ${colors.main};
  /* background-color: #ef0d33; */
  border-radius: 2px 2px 2px 2px;
  font: normal normal 700 normal 15px / 18px 'Maison Neue', sans-serif;
  margin: 0;
  outline: rgb(255, 255, 255) none 0px;
  transition: transform 0.9s ease 0.25s, opacity 0.9s ease 0.25s;
  @media (max-width: ${widthMobile}) {
    margin: 0 auto;
  }
  &:after {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 18px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
  }
  &:before {
    bottom: -4px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    content: '"See' 'case' 'study"';
    display: block;
    height: 64px;
    left: 0px;
    letter-spacing: 1px;
    opacity: 0;
    position: absolute;
    right: 0px;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    top: 0px;
    width: 236px;
    z-index: 1;
    column-rule-color: rgb(255, 255, 255);
    perspective-origin: 118px 32px;
    transform: matrix(1, 0, 0, 1, 118, 0);
    transform-origin: 118px 32px;
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 64px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
    transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
  }
`
const SPAN3 = styled.span`
  bottom: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  height: 60px;
  left: -94.3906px;
  letter-spacing: 1px;
  opacity: 0;
  position: absolute;
  right: 0px;
  text-align: left;
  text-decoration: none solid rgb(255, 255, 255);
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  text-transform: uppercase;
  top: 0px;
  width: 330.391px;
  column-rule-color: rgb(255, 255, 255);
  perspective-origin: 165.188px 30px;
  transform: matrix(1, 0, 0, 1, 330, 0);
  transform-origin: 165.188px 30px;
  caret-color: rgb(255, 255, 255);
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box
    border-box;
  border: 0px none rgb(255, 255, 255);
  border-radius: 2px 2px 2px 2px;
  font: normal normal 700 normal 15px / 18px 'Maison Neue', sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  transition: transform 0.5s ease 0s, opacity 0.3s ease 0s;
  &:after {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    letter-spacing: 1px;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 18px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
  }
  &:before {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    letter-spacing: 1px;
    text-align: left;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 18px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
  }
`
const SPAN4 = styled.span`
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  height: 64px;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none solid rgb(255, 255, 255);
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  text-transform: uppercase;
  width: 236px;
  column-rule-color: rgb(255, 255, 255);
  perspective-origin: 118px 32px;
  transform-origin: 118px 32px;
  caret-color: rgb(255, 255, 255);
  border: 0px none rgb(255, 255, 255);
  font: normal normal 700 normal 15px / 64px 'Maison Neue', sans-serif;
  outline: rgb(255, 255, 255) none 0px;
  transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
  &:after {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 64px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
  }
  &:before {
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    column-rule-color: rgb(255, 255, 255);
    caret-color: rgb(255, 255, 255);
    border: 0px none rgb(255, 255, 255);
    font: normal normal 700 normal 15px / 64px 'Maison Neue', sans-serif;
    outline: rgb(255, 255, 255) none 0px;
  }
`
class CTAText extends Component {
  render() {
    const { children } = this.props
    return (
      <DIV1>
        <A2
          href="mailto:info@baulen.es?subject=[CONTACTO%20WEB]%20"
          target="_blank"
          rel=""
        >
          <SPAN3 />
          <SPAN4>{children}</SPAN4>
        </A2>
      </DIV1>
    )
  }
}

CTAText.propTypes = {
  children: PropTypes.string,
}

export default CTAText
