import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CTAText from './CTAText'
import colors from '../../constants/colors'
import { marginLeftRitght, widthMobile } from '../../constants/sizes'

const TitleCTAWrapper = styled.div`
  padding-left: ${marginLeftRitght};
  @media (max-width: ${widthMobile}) {
    padding-right: 5vw;
    padding-left: 5vw;
  }
`
const Title = styled.h2`
  color: rgb(255, 255, 255);
  font-family: 'Trump Gothic East', sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 96px;
  @media (max-width: ${widthMobile}) {
    font-size: 50px;
    line-height: 60px;
    text-align: center;

  }
  text-align: left;
  text-transform: uppercase;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-delay: 0.15s, 0.15s;
  transition-duration: 0.9s, 0.9s;
  transition-property: transform, opacity;
  transition-timing-function: ease, ease;
  width: 666px;
  max-width: 100%;
`
const Text = styled.p`
  color: rgb(255, 255, 255);
  font-family: 'Maison Neue', sans-serif;
  font-size: 18px;
  line-height: 25px;
  /* margin-block-start: 30px; */
  /* margin-top: 30px; */
  text-align: left;
  transition-delay: 0.15s, 0.15s;
  transition-duration: 0.9s, 0.9s;
  transition-property: transform, opacity;
  transition-timing-function: ease, ease;
  visibility: visible;
  @media (max-width: ${widthMobile}) {
    text-align: center;
  }
`
const Barra = styled.div`
  background-color: ${colors.main};
  box-sizing: border-box;
  color: rgb(17, 21, 23);
  display: block;
  font-family: 'Maison Neue', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  height: 4px;
  margin-bottom: 30px;
  margin-left: 0px;
  margin-right: 626px;
  margin-top: 30px;
  opacity: 1;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition-delay: 0.25s, 0.25s;
  transition-duration: 0.9s, 0.9s;
  transition-property: transform, opacity;
  transition-timing-function: ease, ease;
  visibility: visible;
  width: 40px;
  -webkit-font-smoothing: antialiased;
  @media (max-width: ${widthMobile}) {
    margin-left: auto;
    margin-right: auto;
  }
`

export default class TitleCTA extends PureComponent {
  render() {
    const { title, text, ctaText } = this.props
    return (
      <TitleCTAWrapper className="title-cta">
        {title && (
          <>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Barra />
          </>
        )}
        {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
        {ctaText && <CTAText>{ctaText}</CTAText>}
      </TitleCTAWrapper>
    )
  }
}

TitleCTA.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
}
